* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 1px;
    scroll-behavior: smooth;
}
*::selection {
    background: $dark-red;
    color: $main-color;
}
#root {
    position: relative;
}
body {
    scroll-behavior: smooth;
    font-size: 10px;
    font-family: $main-font;
    background: $main-color;
    color: $dark-red;
    overflow: hidden visible;
    position: relative;
    height: 500vh;
}

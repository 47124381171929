footer {
    position: fixed;
    background: $main-dark-color;
    color: $main-gray;
    z-index: 999999999;
    display: inline-block;
    @include main-shadow;
    @include main-radius;
    top: 15px;
    &::after,
    &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 20px;
        background: $dark-red;
        top: -15px;
        z-index: 9999999;
    }
    &::after {
        right: 35px;
    }
    &::before {
        left: 35px;
    }
    p {
        font-size: 1.5em;
        line-height: 2;
        font-family: $Consolas-Font;
    }
}

.card_box {
    position: relative;
    transform-style: preserve-3d;
    perspective: 1000px;
    & * {
        transition: all 1s ease;
    }
    &:hover {
        &::after,
        &::before {
            z-index: -9999;
        }
        .cards_card {
            transform: rotateY(-180deg) translateX(100%);
            transform-origin: right center;
            @include main-radius($radius: 15px);
            .face {
                h4 {
                    transform-origin: bottom;
                    transform: translateY(30px);
                    box-shadow: 0 -10px 20px 0 $dark-red;
                }
                ul {
                    transform: rotateX(180deg) translateY(-45px);
                    svg {
                        transform: rotateX(180deg) rotateY(180deg);
                    }
                }
            }
            .back {
                border: 3px solid $main-dark-color;
            }
        }
        &:nth-child(3) {
            .cards_card {
                transform-origin: left center;
                transform: rotateY(180deg) translateX(-100%);
            }
        }
    }
    .cards_card {
        position: relative;
        transform-style: preserve-3d;
        max-width: 300px;
        transform-origin: right center;

        .together {
            max-width: 300px;
            @include d-flex();
            position: relative;
            transform-style: preserve-3d;
            @include main-radius($radius: 15px);
            background: $main-dark-color;
        }
        .face {
            h4 {
                color: $dark-red;
                background: $main-color;
                position: absolute;
                top: -45px;
                font-size: 3em;
                width: 200px;
                padding: 5px 0 20px;
                text-align: center;
                border: {
                    style: solid;
                    width: 4px;
                    color: $main-gray;
                }
                @include main-radius($radius: 15px);
                z-index: -1;
            }
            .image {
                @include main-radius($radius: 15px);
                max-width: 100%;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }
            ul {
                position: absolute;
                @include d-flex($column-gap: 25px);
                bottom: -20px;
                list-style-type: none;
                background: $dark-red;
                @include main-radius();
                padding: 2px 25px;
                border: 3px solid $main-dark-color;
                transform: translateZ(50px);
                li {
                    cursor: pointer;
                    svg {
                        position: relative;
                        max-width: 30px;

                        &:hover {
                            path {
                                transition: stroke 0.4s ease;
                                stroke: #fff;
                                stroke-dashoffset: 5px;
                            }
                        }
                    }
                    &:last-child {
                        svg {
                            max-width: 20px;
                        }
                    }
                }
            }
        }
        .back {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            min-width: 300px;
            transform: rotateY(180deg);
            backface-visibility: hidden;
            border: 3px solid $main-dark-color;
            padding: 0 30px;
            text-align: left;
            align-content: center;
            background: linear-gradient(160deg, rgb(56, 0, 0), $main-color);
            h5 {
                font-size: 4em;
                color: #f1f3f6;
                width: 100%;
                margin-bottom: 15px;
            }
            p {
                color: #f1f3f6;
                line-height: 1.6;
            }
        }
    }

    &:nth-child(1) {
        align-self: flex-start;
        .cards_card {
            .face {
                h4 {
                    color: $main-gray;
                }
                ul {
                    background: $main-gray;
                }
            }
        }
        &::before,
        &::after {
            position: absolute;
            content: "";
        }
        &::before {
            width: 100vw;
            height: 5px;
            background: $main-gray;
            top: 50%;
            right: 100%;
        }
        &::after {
            width: 30px;
            left: -15px;
            height: 50%;
            top: 25%;
            background: $main-gray;
            @include main-radius();
        }
    }
    &:nth-child(2) {
        .cards_card {
            position: relative;
            .together {
                border: 2px solid $dark-red;
                min-height: 450px;

                @include d-flex($align-items: flex-start);
                @include main-radius($radius: 11px);
            }
            .face {
                border: 2px solid $dark-red;
                max-width: 300px;
                min-height: 450px;
                h4 {
                    position: relative;
                    background: transparent;
                    top: -15px;
                    border: none;
                    order: 2;
                    font-weight: 900;
                    font-size: 3em;
                    width: 100%;
                }
                .image {
                    border-bottom-right-radius: 500px;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 11px;
                    border: 2px solid $dark-red;
                }
            }
            .back {
                @include d-flex();
            }
        }
        &::before,
        &::after {
            position: absolute;
            content: "";
        }
        &::before {
            bottom: -100px;
            right: 50%;
            width: 100vw;
            height: 100px;
            border-bottom: 5px solid $dark-red;
            border-right: 5px solid $dark-red;
        }
        &::after {
            top: -105px;
            left: 50%;
            width: 100vw;
            height: 100px;
            border-top: 5px solid $dark-red;
            border-left: 5px solid $dark-red;
        }
    }
    &:nth-child(3) {
        align-self: flex-end;

        .cards_card {
            transform-origin: left center;
            &:hover {
                transform: rotateY(180deg) translateX(-100%);
            }
            .face {
                h4 {
                    border-color: $dark-red;
                }
                .image {
                    border: 4px solid $dark-red;
                }
            }
        }
        &::before,
        &::after {
            position: absolute;
            content: "";
        }
        &::before {
            width: 100vw;
            height: 5px;
            background: $dark-red;
            left: 100%;
            top: 50%;
        }
        &::after {
            top: 25%;
            height: 50%;
            width: 30px;
            @include main-radius();
            background: $dark-red;
            right: -15px;
        }
    }
}

/* Start  Scss */
@font-face {
  font-family: "Westmeath";
  src: url("./Fonts/Westmeath.ttf");
}

/* Mixin */
@-webkit-keyframes anim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes anim {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  margin: 0 auto;
  padding: 0 12px;
}

/* Bootstrap Breakpoints */
/* 
============
==== Min ==== 
============
*/
@media (min-width: 300px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

/*
=============
==== Max ==== 
=============
*/
@media (max-width: 575.98px) {
  body {
    font-size: 6px !important;
  }
  footer.container {
    left: 10%;
  }
  .main-01 {
    overflow-x: hidden !important;
  }
  .main-01 > img {
    top: 150px !important;
    max-width: 100% !important;
  }
  .main-01 .main-01_content .main-01_text {
    padding-left: 5px;
    -webkit-transform: translate3d(0, -60px, 0) !important;
            transform: translate3d(0, -60px, 0) !important;
  }
  .main-01 .main-01_content .main-01_Lottie {
    -webkit-transform: translateY(10px) !important;
            transform: translateY(10px) !important;
  }
  .div-logo {
    position: absolute !important;
    z-index: -9999;
    top: -110px !important;
    left: 0px !important;
    -webkit-transform: rotate(0deg) !important;
            transform: rotate(0deg) !important;
    min-height: 420px !important;
    margin-bottom: -300px;
  }
  .div-logo .image {
    right: 15px !important;
    bottom: -45px !important;
    padding: 30px 10px !important;
  }
  .div-logo .image img {
    -webkit-transform: rotate(0deg) !important;
            transform: rotate(0deg) !important;
  }
  .we-are {
    overflow-x: hidden !important;
  }
  .we-are > img {
    max-width: 100% !important;
  }
}

/* End  Scss */
/* Start  Layout */
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  letter-spacing: 1px;
  scroll-behavior: smooth;
}

*::-moz-selection {
  background: #810000;
  color: #141414;
}

*::selection {
  background: #810000;
  color: #141414;
}

#root {
  position: relative;
}

body {
  scroll-behavior: smooth;
  font-size: 10px;
  font-family: "Westmeath", sans-serif;
  background: #141414;
  color: #810000;
  overflow: hidden visible;
  position: relative;
  height: 500vh;
}

/* End  Layout */
/* Start  Buttons */
/* End  Buttons */
/* Start  Footer */
footer {
  position: fixed;
  background: #000;
  color: #888888;
  z-index: 999999999;
  display: inline-block;
  -webkit-box-shadow: 0 1px 0 #810000;
          box-shadow: 0 1px 0 #810000;
  border-radius: 25px;
  top: 15px;
}

footer::after, footer::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #810000;
  top: -15px;
  z-index: 9999999;
}

footer::after {
  right: 35px;
}

footer::before {
  left: 35px;
}

footer p {
  font-size: 1.5em;
  line-height: 2;
  font-family: "Consolas";
}

/* End  Footer */
/* Start  Header */
header {
  z-index: 9999;
  display: block;
  bottom: 0;
  font-size: 1.5em;
  width: 100vw;
  position: fixed;
  height: 200px;
  padding-left: 100px;
  padding-right: 100px;
}

header * {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

header ul {
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  -webkit-box-shadow: 0 1px 0 #810000;
          box-shadow: 0 1px 0 #810000;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: no-wrap;
      flex-wrap: no-wrap;
  row-gap: 0;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-right: 15px;
  padding-left: 15px;
  position: absolute;
  bottom: 15%;
  list-style: none;
}

header ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

header ul li span {
  width: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  overflow: hidden;
}

header ul li svg path {
  stroke: #888888;
}

header ul li.active {
  padding: 5px 15px;
  background: #000;
  color: #888888;
  display: inline-block;
  -webkit-box-shadow: 0 1px 0 #810000;
          box-shadow: 0 1px 0 #810000;
  border-radius: 12px;
}

header ul li.active span {
  margin-left: 10px;
  position: relative;
  top: -4px;
  width: auto;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

header ul li.active svg path {
  stroke: #ff0000;
}

/* End  Header */
/* Start  Div Logo */
@media (min-width: 1441px) {
  .div-logo {
    top: -970px !important;
    left: 300px !important;
  }
}

@media (min-width: 1025px) {
  .div-logo {
    top: -970px !important;
    left: 300px !important;
  }
}

.div-logo {
  width: 100%;
  min-height: 1000px;
  max-height: 1500px;
  top: -780px;
  left: -20px;
  -webkit-box-shadow: 0 0 20px 10px #81000082;
          box-shadow: 0 0 20px 10px #81000082;
  border-left: 5px solid;
  border-bottom: 5px solid;
  border-bottom-left-radius: 15px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background: #141414;
  position: absolute;
  -webkit-transform: rotate(-28deg);
          transform: rotate(-28deg);
  z-index: -9;
}

.div-logo .image {
  position: absolute;
  left: 70%;
  bottom: -60px;
  background: #141414;
  max-width: 130px;
  padding: 45px 10px;
  border-style: solid;
  border-width: 3px;
  border-radius: 10px;
}

.div-logo .image img {
  width: 100%;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}

/* End  Div Logo */
/* Start  main - 01 */
.main-01 {
  font-family: "Consolas";
  position: relative;
}

.main-01 .container {
  position: relative;
}

.main-01 .container h1.title-heading {
  position: absolute;
  font-size: 20em;
  bottom: -450px;
  left: -180px;
  font-family: "Westmeath";
  z-index: -9;
}

.main-01 .container h1.title-heading::after {
  position: absolute;
  content: '"';
  top: 80px;
  left: 500px;
  font-size: 2em;
  line-height: 0;
  width: 100%;
  height: 100px;
}

.main-01 > img {
  right: 0;
  top: -180px;
  position: absolute;
  z-index: -999;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.main-01 .main-01_content {
  margin-top: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  row-gap: 0;
  -webkit-column-gap: 0;
          column-gap: 0;
}

.main-01 .main-01_content .main-01_text {
  z-index: -1;
}

.main-01 .main-01_content .main-01_text * {
  letter-spacing: 2px;
  -webkit-transition: all 1s ease !important;
  transition: all 1s ease !important;
}

.main-01 .main-01_content .main-01_text h1 {
  color: #d2d2d2;
  font-size: 5em;
  margin-bottom: 15px;
}

.main-01 .main-01_content .main-01_text h1 span {
  color: #810000;
  position: relative;
}

.main-01 .main-01_content .main-01_text h1 span::after {
  -webkit-animation: anim 1s ease-in-out infinite;
          animation: anim 1s ease-in-out infinite;
  position: absolute;
  content: "_";
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #141414;
}

.main-01 .main-01_content .main-01_text p {
  font-size: 2em;
  max-width: 650px;
  line-height: 1.8;
  color: #888888;
}

.main-01 .main-01_content .main-01_text p i {
  color: #810000;
}

.main-01 .main-01_content .main-01_text p span {
  color: #810000;
  position: relative;
  -webkit-animation: anim 1s ease-in-out infinite;
          animation: anim 1s ease-in-out infinite;
}

.main-01 .main-01_content .main-01_Lottie {
  -ms-flex-item-align: end;
      align-self: flex-end;
  -webkit-transform: translateY(300px);
          transform: translateY(300px);
}

.main-01 .main-01_content .main-01_Lottie svg {
  max-width: 400px;
  -webkit-transform: translate3d(0, 0, 0) rotateY(180deg) !important;
          transform: translate3d(0, 0, 0) rotateY(180deg) !important;
}

/* End  main - 01 */
/* Start  We Are */
.we-are {
  position: relative;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 600px;
}

.we-are * {
  font-family: "Consolas";
}

.we-are > img {
  position: absolute;
  left: 0;
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
  z-index: -99999;
}

.we-are .cards {
  padding-top: 200px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  row-gap: 0;
  -webkit-column-gap: 15em;
          column-gap: 15em;
}

.we-are .cards h1.title-heading {
  position: absolute;
  font-size: 18em;
  bottom: -248px;
  left: -180px;
  font-family: "Westmeath";
  line-height: 0.9;
  z-index: -9;
}

.we-are .cards h1.title-heading::after {
  position: absolute;
  content: '"';
  top: 150px;
  left: 300px;
  font-size: 2em;
  line-height: 0;
  width: 100%;
  height: 100px;
}

.we-are .cards .card_box {
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.we-are .cards .card_box * {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.we-are .cards .card_box:hover::after, .we-are .cards .card_box:hover::before {
  z-index: -9999;
}

.we-are .cards .card_box:hover .cards_card {
  -webkit-transform: rotateY(-180deg) translateX(100%);
          transform: rotateY(-180deg) translateX(100%);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  border-radius: 15px;
}

.we-are .cards .card_box:hover .cards_card .face h4 {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-box-shadow: 0 -10px 20px 0 #810000;
          box-shadow: 0 -10px 20px 0 #810000;
}

.we-are .cards .card_box:hover .cards_card .face ul {
  -webkit-transform: rotateX(180deg) translateY(-45px);
          transform: rotateX(180deg) translateY(-45px);
}

.we-are .cards .card_box:hover .cards_card .face ul svg {
  -webkit-transform: rotateX(180deg) rotateY(180deg);
          transform: rotateX(180deg) rotateY(180deg);
}

.we-are .cards .card_box:hover .cards_card .back {
  border: 3px solid #000;
}

.we-are .cards .card_box:hover:nth-child(3) .cards_card {
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: rotateY(180deg) translateX(-100%);
          transform: rotateY(180deg) translateX(-100%);
}

.we-are .cards .card_box .cards_card {
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  max-width: 300px;
  -webkit-transform-origin: right center;
          transform-origin: right center;
}

.we-are .cards .card_box .cards_card .together {
  max-width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  row-gap: 0;
  -webkit-column-gap: 0;
          column-gap: 0;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  border-radius: 15px;
  background: #000;
}

.we-are .cards .card_box .cards_card .face h4 {
  color: #810000;
  background: #141414;
  position: absolute;
  top: -45px;
  font-size: 3em;
  width: 200px;
  padding: 5px 0 20px;
  text-align: center;
  border-style: solid;
  border-width: 4px;
  border-color: #888888;
  border-radius: 15px;
  z-index: -1;
}

.we-are .cards .card_box .cards_card .face .image {
  border-radius: 15px;
  max-width: 100%;
  overflow: hidden;
}

.we-are .cards .card_box .cards_card .face .image img {
  width: 100%;
}

.we-are .cards .card_box .cards_card .face ul {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  row-gap: 0;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  bottom: -20px;
  list-style-type: none;
  background: #810000;
  border-radius: 25px;
  padding: 2px 25px;
  border: 3px solid #000;
  -webkit-transform: translateZ(50px);
          transform: translateZ(50px);
}

.we-are .cards .card_box .cards_card .face ul li {
  cursor: pointer;
}

.we-are .cards .card_box .cards_card .face ul li svg {
  position: relative;
  max-width: 30px;
}

.we-are .cards .card_box .cards_card .face ul li svg:hover path {
  -webkit-transition: stroke 0.4s ease;
  transition: stroke 0.4s ease;
  stroke: #fff;
  stroke-dashoffset: 5px;
}

.we-are .cards .card_box .cards_card .face ul li:last-child svg {
  max-width: 20px;
}

.we-are .cards .card_box .cards_card .back {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 300px;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border: 3px solid #000;
  padding: 0 30px;
  text-align: left;
  -ms-flex-line-pack: center;
      align-content: center;
  background: linear-gradient(160deg, #380000, #141414);
}

.we-are .cards .card_box .cards_card .back h5 {
  font-size: 4em;
  color: #f1f3f6;
  width: 100%;
  margin-bottom: 15px;
}

.we-are .cards .card_box .cards_card .back p {
  color: #f1f3f6;
  line-height: 1.6;
}

.we-are .cards .card_box:nth-child(1) {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.we-are .cards .card_box:nth-child(1) .cards_card .face h4 {
  color: #888888;
}

.we-are .cards .card_box:nth-child(1) .cards_card .face ul {
  background: #888888;
}

.we-are .cards .card_box:nth-child(1)::before, .we-are .cards .card_box:nth-child(1)::after {
  position: absolute;
  content: "";
}

.we-are .cards .card_box:nth-child(1)::before {
  width: 100vw;
  height: 5px;
  background: #888888;
  top: 50%;
  right: 100%;
}

.we-are .cards .card_box:nth-child(1)::after {
  width: 30px;
  left: -15px;
  height: 50%;
  top: 25%;
  background: #888888;
  border-radius: 25px;
}

.we-are .cards .card_box:nth-child(2) .cards_card {
  position: relative;
}

.we-are .cards .card_box:nth-child(2) .cards_card .together {
  border: 2px solid #810000;
  min-height: 450px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  row-gap: 0;
  -webkit-column-gap: 0;
          column-gap: 0;
  border-radius: 11px;
}

.we-are .cards .card_box:nth-child(2) .cards_card .face {
  border: 2px solid #810000;
  max-width: 300px;
  min-height: 450px;
}

.we-are .cards .card_box:nth-child(2) .cards_card .face h4 {
  position: relative;
  background: transparent;
  top: -15px;
  border: none;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  font-weight: 900;
  font-size: 3em;
  width: 100%;
}

.we-are .cards .card_box:nth-child(2) .cards_card .face .image {
  border-bottom-right-radius: 500px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 11px;
  border: 2px solid #810000;
}

.we-are .cards .card_box:nth-child(2) .cards_card .back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  row-gap: 0;
  -webkit-column-gap: 0;
          column-gap: 0;
}

.we-are .cards .card_box:nth-child(2)::before, .we-are .cards .card_box:nth-child(2)::after {
  position: absolute;
  content: "";
}

.we-are .cards .card_box:nth-child(2)::before {
  bottom: -100px;
  right: 50%;
  width: 100vw;
  height: 100px;
  border-bottom: 5px solid #810000;
  border-right: 5px solid #810000;
}

.we-are .cards .card_box:nth-child(2)::after {
  top: -105px;
  left: 50%;
  width: 100vw;
  height: 100px;
  border-top: 5px solid #810000;
  border-left: 5px solid #810000;
}

.we-are .cards .card_box:nth-child(3) {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.we-are .cards .card_box:nth-child(3) .cards_card {
  -webkit-transform-origin: left center;
          transform-origin: left center;
}

.we-are .cards .card_box:nth-child(3) .cards_card:hover {
  -webkit-transform: rotateY(180deg) translateX(-100%);
          transform: rotateY(180deg) translateX(-100%);
}

.we-are .cards .card_box:nth-child(3) .cards_card .face h4 {
  border-color: #810000;
}

.we-are .cards .card_box:nth-child(3) .cards_card .face .image {
  border: 4px solid #810000;
}

.we-are .cards .card_box:nth-child(3)::before, .we-are .cards .card_box:nth-child(3)::after {
  position: absolute;
  content: "";
}

.we-are .cards .card_box:nth-child(3)::before {
  width: 100vw;
  height: 5px;
  background: #810000;
  left: 100%;
  top: 50%;
}

.we-are .cards .card_box:nth-child(3)::after {
  top: 25%;
  height: 50%;
  width: 30px;
  border-radius: 25px;
  background: #810000;
  right: -15px;
}

/* End  We Are */
/* Start Custom Cursor  */
/* End Custom Cursor  */

header {
    z-index: 9999;
    & * {
        transition: all 0.3s ease;
    }
    display: block;
    bottom: 0;
    font-size: 1.5em;
    width: 100vw;
    position: fixed;
    height: 200px;
    padding-left: 100px;
    padding-right: 100px;
    ul {
        backdrop-filter: blur(8px);
        transition: all 0.8s ease;
        @include main-shadow;
        @include main-radius($radius: 12px);
        @include d-flex($wrap: no-wrap, $column-gap: 20px);
        padding: {
            top: 10px;
            bottom: 8px;
            right: 15px;
            left: 15px;
        }
        position: absolute;
        bottom: 15%;
        list-style: none;
        li {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            span {
                width: 0;
                transform: scale(0);
                overflow: hidden;
            }
            svg {
                path {
                    stroke: $main-gray;
                }
            }

            &.active {
                padding: 5px 15px;
                background: $main-dark-color;
                color: $main-gray;
                display: inline-block;
                @include main-shadow;
                @include main-radius($radius: 12px);
                span {
                    margin-left: 10px;
                    position: relative;
                    top: -4px;
                    width: auto;
                    transform: scale(1);
                    transform-origin: bottom;
                }
                svg {
                    path {
                        stroke: $light-red;
                    }
                }
            }
        }
    }
}

@mixin animationForSpan {
    animation: anim 1s ease-in-out infinite;
}
@mixin animationForSVG {
    // animation: animSvg 3s ease-in-out both infinite;
    // animation-delay: 6s;
}

// @keyframes animSvg {
//     0% {
//         stroke: $dark-red;
//     }
//     50% {
//         stroke: $main-color;
//     }
//     100% {
//         stroke: $dark-red;
//     }
// }
@keyframes anim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.main-01 {
    font-family: $Consolas-Font;

    position: relative;
    .container {
        position: relative;

        h1.title-heading {
            position: absolute;
            font-size: 20em;
            bottom: -450px;
            left: -180px;
            font-family: "Westmeath";
            z-index: -9;
            &::after {
                position: absolute;
                content: '"';
                top: 80px;
                left: 500px;
                font-size: 2em;
                line-height: 0;
                width: 100%;
                height: 100px;
            }
        }
    }
    > img {
        right: 0;
        top: -180px;
        position: absolute;
        z-index: -999;
        transform: rotateY(180deg);
    }

    .main-01_content {
        .text {
            // transform: translateY(-10px);
        }
        margin-top: 150px;
        @include d-flex($justify-content: space-between);
        @import "./text";
        @import "./LottieFile";
    }
}

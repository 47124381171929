.main-01_text {
    z-index: -1;
    & * {
        letter-spacing: 2px;
        transition: all 1s ease !important;
    }
    h1 {
        color: $main-light-gray;
        font-size: 5em;
        margin-bottom: 15px;
        span {
            color: $dark-red;
            position: relative;
            &::after {
                @include animationForSpan;
                position: absolute;
                content: "_";
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: $main-color;
            }
        }
    }

    p {
        font-size: 2em;
        max-width: 650px;
        line-height: 1.8;
        color: $main-gray;
        i {
            color: $dark-red;
        }
        span {
            color: $dark-red;

            position: relative;
            @include animationForSpan;
        }
    }
}

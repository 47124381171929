.container {
    margin: 0 auto;
    padding: 0 12px;
}

/* Bootstrap Breakpoints */
/* 
============
==== Min ==== 
============
*/

// Extra Small devices (landscape phones, 200px and up)
@media (min-width: 300px) {
    .container {
        max-width: 100%;
    }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}

/*
=============
==== Max ==== 
=============
*/
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    body {
        font-size: 6px !important;
    }
    footer.container {
        left: 10%;
    }
    .main-01 {
        overflow-x: hidden !important;

        > img {
            top: 150px !important;
            max-width: 100% !important;
        }
        .main-01_content {
            .main-01_text {
                padding-left: 5px;
                transform: translate3d(0, -60px, 0) !important;
            }
            .main-01_Lottie {
                transform: translateY(10px) !important;
            }
        }
    }
    .div-logo {
        position: absolute !important;
        z-index: -9999;
        top: -110px !important;
        left: 0px !important;
        transform: rotate(0deg) !important;
        min-height: 420px !important;
        margin-bottom: -300px;
        .image {
            right: 15px !important;
            bottom: -45px !important;
            padding: 30px 10px !important;
            img {
                transform: rotate(0deg) !important;
            }
        }
    }
    .we-are {
        overflow-x: hidden !important;
        > img {
            max-width: 100% !important;
        }
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}

// XX-Large devices (larger desktops)
// No media query since the xxl breakpoint has no upper bound on its width

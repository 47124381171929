/* Mixin */
@mixin d-flex(
    $display: flex,
    $justify-content: center,
    $align-items: center,
    $wrap: wrap,
    $row-gap: 0,
    $column-gap: 0
) {
    display: $display;
    justify-content: $justify-content;
    align-items: $align-items;
    flex-wrap: $wrap;
    row-gap: $row-gap;
    column-gap: $column-gap;
}
@mixin p-absolute($position: absolute, $top: 0, $left: 0, $width: 100%) {
    position: $position;
    top: $top;
    left: $left;
    width: $width;
}
@mixin transition($element: all, $time: 0.3s, $time-fun: ease-in-out) {
    transition-property: $element;
    transition-duration: $time;
    transition-timing-function: $time-fun;
}

@mixin main-shadow {
    box-shadow: 0 1px 0 $dark-red;
}
@mixin main-radius($radius: 25px) {
    border-radius: $radius;
}

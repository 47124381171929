@media (min-width: 1441px) {
    .div-logo {
        top: -970px !important;
        left: 300px !important;
    }
}
@media (min-width: 1025px) {
    .div-logo {
        top: -970px !important;
        left: 300px !important;
    }
}
.div-logo {
    width: 100%;
    min-height: 1000px;
    max-height: 1500px;
    top: -780px;
    left: -20px;
    box-shadow: 0 0 20px 10px #81000082;
    border: {
        left: 5px solid;
        bottom: 5px solid;
        bottom-left-radius: 15px;
    }
    backdrop-filter: blur(10px);

    background: #141414;
    position: absolute;
    transform: rotate(-28deg);
    z-index: -9;
    .image {
        position: absolute;
        // left: 1200px;
        left: 70%;
        bottom: -60px;
        background: $main-color;
        max-width: 130px;
        padding: 45px 10px;
        border: {
            style: solid;
            width: 3px;
            radius: 10px;
        }
        img {
            width: 100%;
            transform: rotate(30deg);
        }
    }
}

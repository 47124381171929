.we-are {
    position: relative;
    & * {
        font-family: $Consolas-Font;
    }
    > img {
        position: absolute;
        left: 0;
        transform: rotateY(0);
        z-index: -99999;
    }
    position: relative;
    flex-direction: row;
    margin-top: 600px;

    .cards {
        padding-top: 200px;
        position: relative;
        h1.title-heading {
            position: absolute;
            font-size: 18em;
            bottom: -248px;
            left: -180px;
            font-family: "Westmeath";
            line-height: 0.9;
            z-index: -9;
            &::after {
                position: absolute;
                content: '"';
                top: 150px;
                left: 300px;
                font-size: 2em;
                line-height: 0;
                width: 100%;
                height: 100px;
            }
        }
        @include d-flex($column-gap: 15em);
        @import "./Card";
    }
}
